<op-content-loader viewBox="0 0 1080 200">
  <svg:rect x="0" y="20" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="56" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="92" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="128" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="164" width="100%" height="32" rx="1" />
</op-content-loader>
