<div
  class="spot-modal spot-modal_wide"
>
  <div class="spot-modal--header">
    <span class="spot-modal--header-title">{{text.header}}</span>
    <button
      type="button"
      class="button button_no-margin"
      (click)="openStorageLocation()"
    >
      <span>{{text.buttons.openStorage()}}</span>
      <span class="spot-icon spot-icon_external-link"></span>
    </button>
  </div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <ng-container *ngIf="(loading$ | async) === false; else loadingTemplate">
      <spot-breadcrumbs [content]="breadcrumbs"></spot-breadcrumbs>

      <ul
        class="spot-list spot-list_compact op-file-list"
        data-qa-selector="op-files-picker-modal--file-list"
      >
        <li
          *ngFor="let file of storageFiles$ | async | sortFiles; let i = index;"
          class="spot-list--item op-file-list--item"
          data-qa-selector="op-file-list--item"
          op-storage-file-list-item
          [fileListItemContent]="file"
        ></li>
      </ul>
    </ng-container>

    <ng-template #loadingTemplate>
      <op-loading-file-list></op-loading-file-list>
    </ng-template>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button spot-action-bar--action"
        [textContent]="text.buttons.cancel"
        (click)="closeMe()"
      ></button>
      <button
        type="button"
        class="button spot-action-bar--action"
        [ngClass]="{ '-highlight': selectedFileCount > 0 }"
        [disabled]="selectedFileCount === 0"
        (click)="createSelectedFileLinks()"
      >
        <span class="spot-icon spot-icon_add-link"></span>
        <span>{{selectedFileCount > 0 ? text.buttons.submit() : text.buttons.submitEmptySelection}}</span>
      </button>
    </div>
  </div>
</div>
